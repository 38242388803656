<template>
  <div>
    <div class="video-box">
      <a-row :gutter="[30, 30]">
        <a-col
          :xs="{ span: 24 }"
          :md="{ span: 12 }"
          :lg="{ span: 8 }"
          v-for="(item,index) in list"
          :key="item.url"
          @click="seeDetails(item)"
        >
          <div class="video-main" :class="{'border-no':list.length-1===index}">
            <img class="img" :src="item.img" alt="" />
            <div class="model">
              <img src="../../assets/image/play.png" alt="">
            </div>
            <div class="time" v-if="screenWidth > 768">{{ item.time }}</div>
            <div class="title">{{ item.title }}</div>
            <div class="time" v-if="screenWidth < 768">{{ item.time }}</div>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import data from './data'


export default {
  name: 'Video',
  data () {
    return {
      screenWidth: document.body.clientWidth,
      type: 1,
      list: [],
      total: 0,
      pageSize: 10,
      pageNo: 1
    }
  },
  methods: {
    async getList () {
      this.list = data['video']
      this.total = this.list.length
    },
    callback (key) {
      this.type = key
      this.pageNo = 1
      this.getList(this.type)
    },
    seeDetails (item) {
      window.open(item.url)
    },
    onChange (cur) {
      this.pageNo = cur
      this.getList(this.type)
    }
  },
  created () {
    this.getList(this.type)
  }
}
</script>

<style lang="less" scoped>
.video-box {
  width: 1200px;
  margin: 30px auto;
  .video-main {
    position: relative;
    margin: 0 auto;
    border: 0;

    .img {
      width: 380px;
      height: 214px;
    }
      .model{
        position: absolute;
        top: 0;
        left: 0;
        width: 380px;
        height: 214px;
        text-align: center;
        line-height: 214px;
        background-color: rgba(3, 13, 30, 0.26);
        img{
          width: 46px;
          height: 46px;
        }
      }
    .title {
      width: 380px;
      height: 50px;
      font-weight: 600;
      font-size: 16px;
      color: #3d3d3d;
      line-height: 22px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
    }
    .desc {
      height: 70px;
      margin-top: 8px;
      font-weight: 400;
      font-size: 16px;
      color: #030d1e;
      line-height: 36px;
      text-align: justify;
    }
    .time {
      height: 20px;
      font-weight: 350;
      font-size: 14px;
      color: #3d3d3d;
      line-height: 20px;
      margin: 8px 0 12px;
    }
    &:hover .title {
      color: #0167ff;
    }
  }

}
@media screen and (max-width: 768px) {
  .video-box {
    width: 100%;
    margin: 16px auto 0;
    .video-main {
      width: calc(100% - 32px);
      position: relative;
      margin: 0 auto;
      border-bottom: 1px solid #ededed;

      .img {
        width: 100%;
        height: 214px;
      }
      .model{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 214px;
        text-align: center;
        line-height: 214px;
         background-color: rgba(3, 13, 30, 0.26);
        img{
          width: 32px;
          height: 32px;
        }
      }
      .title {
        min-height: 17px;
        height: auto;
        font-weight: 600;
        font-size: 12px;
        color: #030d1e;
        line-height: 17px;
        text-align: left;
        margin: 12px 0 6px;
      }
      .time {
        height: 17px;
        font-weight: 350;
        font-size: 12px;
        color: rgba(3, 13, 30, 0.26);
        line-height: 17px;
        text-align: left;
        margin-bottom: 16px;
      }
    }
    .border-no{ border: 0; }

  }
}
</style>
