<template>
  <div class="container">
    <div class="bg_wra">
      <h1>关于寓信</h1>
      <h4>寓信科技是智能资管SaaS开创者</h4>
      <h4>致力于用大数据和人工智能（AI）技术助力居住行业发展</h4>
    </div>
    <div class="nav-warp">
      <div class="navigation solution_part">
        <div class="navigation-item" :class="[type ===1?'active':'']" @click="typeClick(1)">走进寓信</div>
        <div class="navigation-item" :class="[type ===2?'active':'']" @click="typeClick(2)">合作签约</div>
        <div class="navigation-item" :class="[type ===3?'active':'']" @click="typeClick(3)">行业动态</div>
        <div class="navigation-item" :class="[type ===4?'active':'']" @click="typeClick(4)">视频中心</div>
      </div>
    </div>
    <about v-if="type===1" />
    <cooperate v-if="type === 2" />
    <dynamics v-if="type === 3" />
    <videos v-if="type === 4" />


  </div>
</template>

<script>
import About from "../components/aboutUs/about.vue";
import Cooperate from "../components/aboutUs/cooperate.vue";
import Dynamics from "../components/aboutUs/dynamics.vue";
import Videos from "../components/aboutUs/video.vue";


export default {
  name: "AboutUs",
   components: {
     About,
     Cooperate,
     Dynamics,
     Videos
  },
  data() {
    return {
      type:1
    }
  },
  mounted() {
    this.type = 1
  },
  methods: {
    typeClick(type) {
      this.type = type
      document.title = '关于寓信科技-寓信'; // 设置页面标题
      if (type === 3) { 
        document.title = '寓信科技行业动态-寓信'; // 设置页面标题
      }
    }
  },
};
</script>

<style lang="less" scoped>
.bg_wra {
  height: 480px;
  // line-height: 480px;
  padding: 146px 0 0 120px;
  color: #fff;
  background: url("../assets/image/bg_abort.png") no-repeat center;
  background-size: 100% 100%;
  h1 {
    font-weight: 700;
    font-size: 50px;
    color: #fff;
  }
  h4 {
    color: #fff;
    font-weight: 700;
    font-size: 16px;
  }
}
.solution_part {
  width: 1200px;
  margin: 0 auto;
}
.title {
  font-weight: 500;
  color: #3d3d3d;
  font-size: 30px;
  padding-top: 70px;
}
.subtitle {
  font-weight: 400;
  color: #3d3d3d;
  font-size: 16px;
  margin: 24px 0;
}
.mobile-title {
  display: none;
}
.nav-warp {
  border-bottom: 1px solid #eeeeee;

  .navigation {
    display: flex;
    height: 72px;
    line-height: 72px;
    //padding: 0 120px;
    .navigation-item {
      font-size: 16px;
      margin-right: 70px;
      color: #3d3d3d;
    }
    .active{
      color: #1890ff;
    }
  }
}
.introduce-warp {
  flex-wrap: nowrap;
  padding-bottom: 42px;
  background-color: #fff;
  .video {
    width: 442px;
    height: 256px;
    margin: 20px 48px 0 0;
  }
  h3 {
    font-size: 24px;
    font-weight: 600;
    margin-top: 20px;
  }
  p {
    color: #3d3d3d;
    font-size: 16px;
  }
}
.culture-warp {
  background: #f6f7fb;
  padding-bottom: 86px;
  .culture-item {
    display: flex;
    align-items: center;
    color: #3d3d3d;
    font-size: 18px;
    background: #fff;
    padding: 34px 49px;
    position: relative;
    .bg {
      position: absolute;
      bottom: 0;
      left: 149px;
      // width: 208px;
      width: auto;
      height: 42px;
    }
    .item-left {
      margin-right: 45px;
    }
    img {
      height: 58px;
      width: 58px;
    }
    p {
      margin-top: 5px;
      text-align: center;
      color: #2d6ef6;
      font-size: 16px;
      white-space: nowrap;
    }
  }
}
.relation-warp {
  padding-bottom: 120px;
  .padbot-41 {
    padding-bottom: 41px;
  }
  .relation-item {
    padding: 37px 35px;
    background: #f2f4f8;
    height: 275px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .relation-header {
      display: flex;
      align-items: center;

      color: #3d3d3d;
      font-weight: 500;
      font-size: 20px;
      margin-bottom: 27px;
    }
    .relation-text {
      font-weight: 400;
      color: #3d3d3d;
      font-size: 16px;
      margin-top: 12px;
    }

    .icon1 {
      width: 25px;
      height: 35px;
      margin-right: 10px;
    }
    .icon {
      height: 28px;
      width: 28px;
      margin-right: 10px;
    }
    .relation-bottom {
      margin-top: 40px;
    }
  }
}

@media screen and (max-width: 768px) {
  .solution_part {
    width: 100%;
    .title {
      font-weight: 500;
      color: #030d1e;
      font-size: 23px;
      padding-bottom: 16px;
      margin: 0 0 0 20px;
      position: relative;
      padding-top: 30px;
    }
    .title::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 30px;
      border: 2px solid #2d6ef6;
    }
    .subtitle {
      color: #404548;
      font-size: 15px;
    }
  }
  .nav-warp {
    .navigation {
      width: 100%;
      height: 46px;
      line-height: inherit;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 32px;
      .navigation-item {
        margin-right: 0;
      }
    }
  }
  .culture-warp {
    padding: 0 20px 22px 20px;
    .culture-item {
      padding: 26px 16px;
      align-items: flex-start;
      flex-direction: column;
      .culture-text {
        padding-left: 40px;
        font-size: 14px;
        color: #404548;
      }
      .item-left {
        display: flex;
        align-items: center;
        margin: 0 0 10px;
        img {
          height: 24px;
          width: 24px;
        }
      }
      p {
        margin: 0;
        margin-left: 16px;
        font-weight: 600;
        color: #2d6ef6;
        font-size: 17px;
      }
      .bg {
        left: 66px;
      }
    }
  }

  .introduce-warp {
    position: relative;
    .video-warp {
      display: none;
    }
    .mobile-warp {
      padding: 0 20px;
      h3 {
        font-size: 15px;
      }
      p {
        font-size: 14px;
      }
      .hint {
        font-weight: 600;
        color: #2d6ef6;
      }
    }
  }
  .introduce-warp::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 56px;
    width: 260px;
    height: 36px;
    background-image: url("../assets/image/about-mobile.png");
    background-size: 100% 100%;
  }
  .mobile-title {
    display: block;
    font-weight: 500;
    color: #030d1e;
    font-size: 23px;
    padding-bottom: 16px;
    margin: 30px 0 0 20px;
    position: relative;
  }
  .mobile-title::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 30px;
    border: 2px solid #2d6ef6;
  }
  .relation-warp {
    margin: 0 20px;
    padding-bottom: 60px;
    .mar-top22 {
      margin-top: 22px;
    }
    .relation-item {
      padding: 26px 16px;
      height: auto;
      .relation-header {
        color: #030d1e;
        font-weight: 600;
        font-size: 17px;
        margin-bottom: 19px;
      }
      .relation-text {
        font-size: 14px;
        margin-top: 6px;
      }
      .blue {
        font-weight: 700;
        color: #0266ff;
      }
      .blue2 {
        font-weight: 700;
        color: #0266ff;
        font-size: 16px;
      }
      .phone {
        font-size: 16px;
        color: #404548;
        font-weight: 700;
      }
      .relation-bottom {
        font-size: 14px;
      }
      .icon1 {
        height: 26px;
        width: 22px;
      }
    }
  }
  .bg_wra {
    height: 260px;
    background: url("../assets/image/about-bg.png") no-repeat center;
    background-size: 100% 100%;
    padding: 94px 20px 0;
    h1 {
      font-size: 26px;
    }
    h4 {
      font-size: 14px;
    }
  }
}
</style>
