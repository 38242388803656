<template>
  <div>
    <div class="news_con">
      <a-row :gutter="[25, 25]">
        <a-col :span="24" :md="24" :sm="24" :xs="24">
          <ul>
            <li
              v-for="(item, index) in list"
              :class="{ 'border-no': list.length - 1 == index }"
              :key="item.id"
              @click="seeDetails(item)"
            >
              <a-row type="flex" class="news-item">
                <a-col class="order">
                  <img :src="item.img" alt="" />
                </a-col>
                <a-col>
                  <div class="news_text">
                    <div class="title">{{ item.title }}</div>
                    <div class="desc">{{ item.subtitle }}</div>
                    <div class="time">{{ item.time }}</div>
                  </div>
                </a-col>
              </a-row>
            </li>
          </ul>
          <!--<a-pagination
            v-model="pageNo"
            :total="total"
            show-less-items
            class="pgn"
            @change="onChange"
          />-->
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import data from './data'

export default {
  name: 'Cooperate',
  data () {
    return {
      type: 1,
      list: [],
      total: 0,
      pageSize: 10,
      pageNo: 1
    }
  },
  methods: {
    async getList () {
      this.list = data['cooperate']

      //const params = {
      //  pageSize: this.pageSize,
      //  pageNo: this.pageNo,
      //  status: 1,
      //  type: type
      //}
      //const res = await getNews(params)
      //if (res.data.status.code == 200) {
      //  this.list = res.data.result
      this.total = this.list.length
      //}
    },
    callback (key) {
      this.type = key
      this.pageNo = 1
      this.getList(this.type)
    },
    seeDetails (item) {
      window.open(item.url)
    },
    onChange (cur) {
      this.pageNo = cur
      this.getList(this.type)
    }
  },
  created () {
    this.getList(this.type)
  }
}
</script>

<style lang="less" scoped>
.bg_wra {
  height: 480px;
  // line-height: 480px;
  padding: 146px 0 0 120px;
  // line-height: 480px;
  // text-align: center;
  font-size: 40px;
  color: #fff;
  background: url('../../assets/image/bg_news.png') no-repeat center;
  background-size: cover;
  h1 {
    font-weight: 700;
    font-size: 58px;
    color: #fff;
  }
  h4 {
    color: #fff;
    font-weight: 700;
    font-size: 28px;
  }
}
.news_con {
  width: 1200px;
  margin: 0 auto;
  ul {
    padding: 0;
    li {
      list-style: none;
      margin: 54px 0;
      cursor: pointer;
      border-bottom: #000;
      .news-item {
        padding-bottom: 54px;
        border-bottom: 1px solid #ededed;
      }
      img {
        width: 450px;
        height: 192px;
        margin-right: 15px;
      }
      .title {
        height: 34px;
        font-weight: 600;
        font-size: 24px;
        color: #3d3d3d;
        line-height: 34px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
      }
      .desc {
        height: 70px;
        margin-top: 8px;
        font-weight: 400;
        font-size: 16px;
        color: #030d1e;
        line-height: 36px;
        text-align: justify;
      }
      .time {
        position: absolute;
        bottom: 10px;
        height: 20px;
        font-weight: 350;
        font-size: 14px;
        color: #3d3d3d;
        line-height: 20px;
        left: 0;
      }
      &:hover .title {
        color: #0167ff;
      }
    }
    .border-no {
      .news-item {
        border: 0;
      }
    }
  }
  .pgn {
    margin-bottom: 30px;
  }
}
.news-banner-warp {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 346px;
  margin: 30px 0;
  .news-banner {
    width: 346px;
    height: 194px;
  }

  .nes-banner-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 1px solid #eff3ff;
    position: relative;
    .code {
      height: 126px;
      width: 126px;
    }
    h4 {
      color: #030d1e;
      font-size: 20px;
    }
    p {
      font-size: 14px;
      color: #607499;
    }
    .conten-bg {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 145px;
    }
  }
}
.news_text {
  width: 672px;
  height: 100%;
  position: relative;
}
@media screen and (max-width: 768px) {
  .bg_wra {
    height: 210px;
    padding: 94px 0 0 20px;
    font-size: 40px;
    color: #fff;
    background: url('../../assets/image/new-bg-moblie.png') no-repeat center;
    background-size: cover;
    h1 {
      font-size: 26px;
      margin-bottom: 0;
    }
    h4 {
      color: #fff;
      font-size: 12px;
      font-weight: 500;
    }
  }
  .news-banner-warp {
    display: none;
  }
  .news-item {
    display: block;
  }

  .news_con {
    width: 100vw;
    ul {
      li {
        margin: 0;
        padding: 16px 16px 0;
        .news-item {
          padding-bottom: 0;
          flex-wrap: nowrap;
          justify-content: space-between;
          border-bottom: 1px solid #ededed;
        }

        .order {
          width: 100%;
          height: 146px;
          margin-bottom: 12px;
        }
        img {
          width: 100%;
          height: 100%;
          margin-right: 0;
          border-radius: 2px;
        }

        .title {
          font-weight: 600;
          font-size: 12px;
          color: #030d1e;
          line-height: 17px;
          text-align: left;
          margin-bottom: 6px;
          min-height: 17px;
          height: auto;
        }
        .desc {
          font-weight: 400;
          font-size: 11px;
          color: #607499;
          line-height: 18px;
          text-align: justify;
          min-height: 18px;
          height: auto;
          margin-top: 0;
        }
        .time {
          position: relative;
          margin-top: 12px;
          height: 17px;
          font-weight: 350;
          font-size: 12px;
          color: rgba(3, 13, 30, 0.26);
          line-height: 17px;
          text-align: left;
        }
      }
      .border-no {
        .news-item {
          border: 0;
        }
      }
    }

    .pgn {
      display: none;
    }
  }
  .news_text {
    width: 100%;
    height: 100%;
    position: relative;
  }
}
</style>
